.footer {
    background-color: white;
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
}

.footer p {
    margin-top: 7px;
}

.footer-brand {
    height: auto;
    padding-top: 2%;
    margin-left: 3%;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
}

.footer-logo {
    max-height: 80px;
}

.footer-text {
    font-size: 14px;
    justify-content: center;
    text-align: center;
    margin: 3% 4%;
}

.social-links {
    padding-left: 3%;
    padding-top: 3%;
}

.social {
    width: 30px;
    height: 30px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    line-height: 30px;
    vertical-align: middle;
    border-radius: 50%;
    color: black;
    background-color: #d1d2d4;
  }

.social::before {
    font-style: normal;
    font-weight: normal;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0;
    text-align: center;
    line-height: 1em;
    justify-content: center;
}
  
.social:hover {
      opacity: 0.7;
      color: white;
  }

  @media screen and (max-width: 979px) {
    .footer {
        display: block;
        padding: 5%;
    }

    .footer-brand {
        width: 50%;
        margin-right: auto;
        margin-left: auto;
    }

    .footer-logo {
        max-height: 80px;
        align-self: center;
        justify-content: center;
    }
    .footer-brand > img {
        justify-content: center;
    }
    .social-links {
        padding-left: none;
        justify-content: center;
    }
}