.contact-us {
    background-image: url('../images/map.jpeg') !important;
    background-repeat: no-repeat;
    background-size: cover;
}

@media only screen and (max-width: 767px) {
    .contact-us {
        background-image: none !important;
        background-repeat: no-repeat;
        background-color: rgba(0,0,0,0.2) !important;
    }
  }


.contact-content {
    padding: 1% 5% 1% 5%;
}

.contact-card {
    background-color: rgba(252, 253, 254, 0.5) !important;
    border: 1px solid #d1d2d4;
    padding: 4% 2%;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    height: 284px;
    padding: 50px 0;
}

.contact-card:hover {
    background-color: rgba(61, 106, 179, 0.7) !important;
}

.contact-card a {
    text-decoration: none
}

.location-img {
    background: url('../images/location.png') center 0 no-repeat;
}

.phone-img {
    background: url('../images/call.png') center 0 no-repeat;
}

.mail-img {
    background: url('../images/mail.png') center 0 no-repeat;
}

.img-frame {
    height: 90px;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
}

.container-icon {
    position: relative;
    overflow: hidden;
}

.contact-icons, .contact-icons-original {
    font-size: 90px;
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s linear 0s;
}

.contact-text {
    font-size: 18px;
    margin-top: 30px !important;
}

.overlay-icon {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.3s ease;
    -webkit-transition: all 0.3s linear 0s;
  }

.contact-card:hover .contact-icons-original {
    transform: translateY(-180%);
}
  
.container-icon:hover .overlay-icon, .contact-card:hover .overlay-icon, .contact-card:hover .contact-text {
    height: 100%;
    color: white !important;
}
