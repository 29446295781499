.body {
    font-family: arial, verdana, sans-serif;
}

.white-text {
  color: #ffffff;
}

.blact-text {
  color: #333333;
}

.grey-text {
  color: #6f6f6f;
}

.blue-text {
  color: #3361A2;
}

.banner-carousel {
  height: calc(100vh - 120px);
  object-fit: cover;
}

.section-head {
    width: 100%;
    background-color: #d1d2d4;
    padding: 25px 0px;
    position: relative;
    float: left;
    border-top-color: #d1d2d4;
}

.section-head h1 {
  color: #6f6f6f;
  margin: 0;
  font-size: 25px;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
}

@media only screen and (max-width: 479px){
  .section-head h1 {
      font-size: 22px;
  }
}

@media (max-width: 767px){
  .section-head h1 {
      text-align: center;
  }
}

.section-head h1:after {
  border-top-color: #d1d2d4;
  content: "";
  display: block;
  position: absolute;
  bottom: -9px;
  margin-left: 40px;
  width: 9px;
  height: 9px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid #d1d2d4;
}

@media (max-width: 767px){
  .section-head h1:after {
      left: 50%;
      margin-left: -9px;
  }
}

.section-body {
  width: 100%;
  background-color: #ffffff;
}

.container {
    margin-right: auto;
    margin-left: auto;
    width: 940px
}

.container:after {
  clear: both;
}

.container:before, .container:after {
  display: table;
  line-height: 0;
  content: "";
}

.content {
    width: 940px;
    float: left;
    min-height: 1px;
    margin-left: 20px;
}

@media (max-width: 767px){
  .container {
    padding-right: 25px;
    padding-left: 25px;
    width: auto;
  }

  .content {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 30px;
    margin-left: 3px;
  }
}

@media (min-width: 768px) and (max-width: 979px) {
  .content {
      width: 724px;
  }

  .container {
    width: 724px;
  }
}

@media (min-width: 1200px){
  .container {
    width: 1170px;
    max-width: 1170px;
    padding-right: 0;
  }

  .content {
    width: 1100px;
    float: left;
    min-height: 1px;
    margin-left: 30px;
  }
}

.inner {
    padding-top: 80px;
    padding-bottom: 80px;
    line-height: 28px;
}

.inner-2 {
  padding-top: 130px;
  padding-bottom: 60px;
  line-height: 28px;
}

.inner-3 {
  padding-top: 80px;
  padding-bottom: 30px;
  line-height: 28px;
}

.content-container {
    margin: 10% 8% 5% 8% !important;
}

  .sub-head {
    color: #484848;
    font-size: 22px;
    line-height: 1;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 15px;
  }

  p {
    margin: 0 0 10px;
    color: black;
  }

  img {
      width: 100%;
  }

  .container-hover {
    position: relative;
    cursor: pointer;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay-partner {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(2,2,2,0.6);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 1s ease;
  }
  
  .container-hover:hover .overlay-partner {
    height: 100%;
  }
  
  .text {
    color: white;
    font-size: 18px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
  }

  .overlay-partner > a {
      text-decoration: none;
      color: white;
  }

  .text > strong {
      font-weight: 800;
  }

  .overlay-1 {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: rgb(190, 190, 190);
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
  }
  
  .container-hover:hover .overlay-1 {
    height: 100%;
    bottom: 0;
  }

  .para {
    position: absolute;
    text-align: left;
    padding: 5% 4%;
  }

  .para h4 {
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 10px;
    margin-top: 5%;
    font-size: 16px;
    line-height: 18px;
  }

  .para p {
    font-size: 15px;
    line-height: 1.1;
    color: #6f6f6f;
  }

  .row>* {
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-left: 0;
  }

  @media (min-width: 1200px){
    .row {
        margin-left: -30px;
    }
 } 

  .detail-buttons {
    display: flex;
    justify-content: space-between;
  }

@media screen and (max-width: 767px) {
  .detail-buttons {
    display: block;
  }
}

.card-btn {
  margin: 0px 3px;
  color: #fff;
  background-color: #d1d2d4;
  border: 1px solid #d1d2d4;
}

.card-btn:hover, .card-btn:focus {
  background-color: #0046d3;
  color: #ffffff;
  border: 1px solid #0046d3;
  box-shadow: none;
}

.card-detail {
  margin-bottom: 30%;
}

.card-detail h3 {
  font-size: 18px;
  line-height: 20px;
  margin: 3% 0% 1% 0%;
  text-transform: uppercase;
  font-weight: 600;
  color: #484848;
}

.card-detail p {
  width: 62%;
  float: left;
  min-height: 1px;
  margin-left: 25px;
  color: #000;
  font-size: 16px;
}