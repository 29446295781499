.navbar {
    padding: 0;
}

.navbar>.container-fluid {
    justify-content: center !important;
    width: 100%;
    margin: 0px auto;
    padding: 0px;
}

.navbar-main {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-brand {
    display: block;
    padding: 0;
    /* margin-left: -20px; */
}


.navbar-logo {
    max-height: 70px !important;
    max-width: 140px;
}

.navbar-tagline {
    font-size: 12px;
    text-transform: none;
    line-height: 1.5;
    color: #777777;
    text-shadow: 0 1px 0 #ffffff;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.6;
}

.nav-display {
    display: flex;
    justify-content: space-between !important;
    margin-top: 38px;
}

.items {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact {
    margin-left: 10px;
}


.navItem {
    font-weight: bold;
    color: #6f6f6f;
    text-shadow: 0 1px 0 #ffffff;
    font-size: 14px;
    line-height: 1;
    padding: 10px !important;
}

.nav-link {
    padding: 0 !important;
}


.navbar-button {
    background-color: #3361A2;
    color: #ffffff;
    border: 1px solid #3361A2;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    padding: 10px;
    margin: 5px;
    transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
}

.navbar-button:hover {
    background-color: #D1D2D4;
    color: #000000;
    border: 1px solid #D1D2D4;
}

.nav-telephone {
    color: #194E84;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.nav-email {
    font-weight: 400;
    font-size: 16px;
    color: #194E84;
    text-align: center;
    justify-content: center;
    
}


@media screen and (max-width: 1024px) {
    .navItem {
        padding: 15px 7px 0 7px;
        font-size: 12px;
    }
    .nav-display {
        display: block;
        top: 5%;
    }

    .navBtn {
        margin-top: 0%;
    }
}



/* Media Query */
@media screen and (max-width: 979px) {
    .navbar-brand {
        display: flex;
        margin: 1px 10px 1px -5px;
    }

    .navbar-logo {
        max-height: 70px !important;
        width: fit-content;
    }

    .navbar-tagline {
        margin-top: 5px;
    }

    .navbar>.container-fluid {
        justify-content: space-between !important;
        display: block;
        padding: 0px 10px
    }
    .navbar-main {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        align-items: flex-end;
    }
}


/* Media Query */
@media screen and (max-width: 767px) {
    .items {
        display: block;
        padding-top: 30px;
    }

    .nav-collapse {
        max-height: 280px;
        overflow: scroll !important;
    }
}



